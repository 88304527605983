import { createEffect, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';

import { requestsClient } from 'shared/api/client';

import {
  GetRolesQueryVariables,
  Role,
  Roles,
} from 'shared/api/apollo/__generated__';

// ----------------------- Get roles -----------------------
const $roles = createStore<Role[]>(null);
const $rolesCount = createStore(0);

const getRoles = createEvent();
const getRolesFx = createEffect<GetRolesQueryVariables, Roles>(
  async (params) => {
    const res = await requestsClient.getRoles(params);
    return res.getRoles;
  },
);

sample({
  clock: getRoles,
  fn: () => ({
    input: {
      pagination: { limit: 20, skip: 0 },
    },
  }),
  target: getRolesFx,
});

sample({
  clock: getRolesFx.doneData,
  fn: (result) => result.nodes,
  target: $roles,
});

sample({
  clock: getRolesFx.doneData,
  fn: (result) => result.count,
  target: $rolesCount,
});

// ----------------------- Reset -----------------------
const reinit = createEvent();

reset({
  clock: reinit,
  target: [$roles, $rolesCount],
});

export const roles = {
  stores: {
    $roles,
    $pending: getRolesFx.pending,
    $rolesCount,
  },
  events: {
    getRoles,
    reinit,
  },
};
