import { Separator } from '@chakra-ui/react';
import {
  DialogBackdrop,
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from 'components/ui/dialog';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import IconClose from 'shared/assets/svg/common/icClose.svg?react';
import { Text } from '../Text';
import { IModal } from './types';

export const CustomModal: FC<IModal> = memo(
  ({
    isOpen,
    onRequestClose,
    children,
    footer,
    title,
    onSubmit,
    modalContentProps,
    dialogRef,
  }) => {
    const { t } = useTranslation();
    return (
      <DialogRoot
        placement='center'
        closeOnEscape
        closeOnInteractOutside
        open={Boolean(isOpen)}
        onExitComplete={onRequestClose}>
        <DialogBackdrop />
        <DialogContent {...modalContentProps} ref={dialogRef}>
          {onSubmit ? (
            <form onSubmit={onSubmit}>
              {title && (
                <>
                  <DialogHeader
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    height={'72px'}>
                    <Text size='lg'>{t(title)}</Text>
                    <IconClose cursor='pointer' onClick={onRequestClose} />
                  </DialogHeader>
                  <Separator />
                </>
              )}
              <DialogBody padding={6}>{children}</DialogBody>
              <DialogFooter>{footer}</DialogFooter>
            </form>
          ) : (
            <>
              {title && (
                <>
                  <DialogHeader
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    height={'72px'}>
                    <Text size='lg'>{t(title)}</Text>
                    <IconClose cursor='pointer' onClick={onRequestClose} />
                  </DialogHeader>
                  <Separator />
                </>
              )}
              <DialogBody padding={6}>{children}</DialogBody>
              <DialogFooter>{footer}</DialogFooter>
            </>
          )}
        </DialogContent>
      </DialogRoot>
    );
  },
);
