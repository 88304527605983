import { InputProps, NumberInputInputProps } from '@chakra-ui/react';
import { PasswordInputProps } from 'components/ui/password-input';
import { ReactElement, RefAttributes } from 'react';
import { TLocaleKey } from 'shared/locales/i18n';

export interface IInput {
  labelKey?: TLocaleKey;
  errorKey?: string;
  helperKey?: string;
  props?: TInputFormatTypes;
  rightContent?: ReactElement;
  leftContent?: ReactElement;
  pending?: boolean;
}

export enum EInputFormatType {
  TEXT = 'text',
  NUMERIC = 'number',
  PASSWORD = 'password',
}

type TInputFormatTypes =
  | {
      type: EInputFormatType.NUMERIC;
      mask?: string;
      inputProps?: NumberInputInputProps & RefAttributes<HTMLInputElement>;
    }
  | {
      type: EInputFormatType.PASSWORD;
      inputProps?: PasswordInputProps & RefAttributes<HTMLInputElement>;
    }
  | {
      type: EInputFormatType.TEXT;
      mask?: string;
      onlyNumbers?: boolean;
      inputProps?: InputProps & RefAttributes<HTMLInputElement>;
    };

export type TInputFormat = {
  type: TInputFormatTypes;
};
