import { Box, Button, Icon } from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { notificationsModel } from 'entities/Notification/model';
import { endPoints } from 'processes/Router/endPoints';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import IconNotifications from 'shared/assets/svg/navbar/icNotifications.svg?react';

export const Notification = () => {
  const navigate = useNavigate();
  const notifications = useUnit(
    notificationsModel.notifications.stores.$notifications,
  );
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    if (notifications) {
      setAnimationKey((prev) => prev + 1);
    }
  }, [notifications]);

  const onNotificationsClick = () => {
    navigate(endPoints.NOTIFICATIONS);
  };

  return (
    <Button onClick={onNotificationsClick} variant='plain' position='relative'>
      <Icon color='white' width={6} height={6}>
        <IconNotifications />
      </Icon>
      {Boolean(notifications?.length) && (
        <Box
          key={animationKey} // Use key to remount and restart animation
          background='red'
          position='absolute'
          width={3}
          height={3}
          top={1}
          right={3}
          borderRadius='50%'
        />
      )}
    </Button>
  );
};
