import { EAuthStages } from '../types';
import { CreatePasswordForm } from '../ui/CreatePasswordForm';
import { ForgotPassword } from '../ui/ForgotPassword';
import { LoginForm } from '../ui/LoginForm';

export const renderContent = {
  [EAuthStages.LOGIN]: <LoginForm />,
  [EAuthStages.SET_PASSWORD]: <CreatePasswordForm />,
  [EAuthStages.FORGOT_PASSWORD]: <ForgotPassword />,
};
