import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/ui/atoms/Button';
import { CustomModal } from 'shared/ui/atoms/CustomModal';

import { Flex } from '@chakra-ui/react';
import { authModel } from 'entities/Auth/model';
import { Text } from 'shared/ui/atoms/Text';
import { logoutModal } from './model/modal';

export const LogoutModal = () => {
  const { t } = useTranslation();
  const [isOpen, pending] = useUnit([
    logoutModal.$store,
    authModel.logout.stores.$pending,
  ]);

  const onCancelClick = () => {
    logoutModal.setVisible(false);
  };

  const onSignOutClick = () => {
    authModel.logout.events.logoutEvent();
  };

  return (
    <CustomModal
      onRequestClose={onCancelClick}
      isOpen={isOpen}
      footer={
        <Flex gap={4}>
          <Button color='secondary' onClick={onCancelClick}>
            {t('actions.cancel')}
          </Button>
          <Button pending={pending} color='danger' onClick={onSignOutClick}>
            {t('logout.logout')}
          </Button>
        </Flex>
      }>
      <Text props={{ textAlign: 'center', fontWeight: 600 }} size='lg'>
        {t('logout.title')}
      </Text>
    </CustomModal>
  );
};
