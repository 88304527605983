import React, { FC } from 'react';
import { useUnit } from 'effector-react';

import { CustomModal } from 'shared/ui/atoms/CustomModal';
import { OTPCodeForm } from '../OTPCodeForm';
import { IOTPCodeModal } from './types';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/atoms/Button';
import { authPagesModel } from 'pages/Auth/model';

export const OTPCodeModal: FC<IOTPCodeModal> = ({
  onCancel,
  modalData,
  form,
}) => {
  const [isOpen, pending] = useUnit([
    modalData.$store,
    authPagesModel.verifyOtp.stores.$pending,
  ]);
  const { t } = useTranslation();

  const onSubmit = (e) => {
    e.preventDefault();
    form.submit();
  };

  const onCancelClick = () => {
    modalData.setVisible(false);
    if (onCancel) onCancel();
  };

  return (
    <CustomModal
      title='otpCodeModal.title'
      onRequestClose={onCancelClick}
      onSubmit={onSubmit}
      isOpen={isOpen}
      footer={
        <Flex gap={4}>
          <Button color='secondary' onClick={onCancelClick}>
            {t('actions.cancel')}
          </Button>
          <Button pending={pending} color='primary' type='submit'>
            {t('actions.confirm')}
          </Button>
        </Flex>
      }>
      <OTPCodeForm form={form} pending={pending} />
    </CustomModal>
  );
};
