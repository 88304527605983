import { createEffect, sample } from 'effector';
import { createForm } from 'effector-forms';
import { showToastEvent } from 'entities/Toast/model/showToast';
import { EToastStatus, IShowToast } from 'entities/Toast/types';

import { requestsClient } from 'shared/api/client';

import { SetPasswordMutationVariables } from 'shared/api/apollo/__generated__';
import { PASSWORD } from 'shared/config/regulars';
import { rules } from 'shared/lib/rules';
import { EAuthStages } from '../types';
import { stage } from './stage';
import { login } from './login';
import { TLocaleKey } from 'shared/locales/i18n';

const form = createForm({
  fields: {
    password: {
      init: '',
      rules: [
        rules.requiredString(),
        rules.invalidLength(8, 128),
        rules.invalidRegexPattern('invalidPassword', PASSWORD),
      ],
    },
    repeatPassword: {
      init: '',
      rules: [
        rules.requiredString(),
        rules.invalidLength(8, 128),
        rules.invalidRegexPattern('invalidPassword', PASSWORD),
        rules.invalidRepeatPassword(),
      ],
    },
  },
  validateOn: ['change', 'submit'],
});

const setPasswordFx = createEffect<SetPasswordMutationVariables, boolean>(
  async (params) => {
    const res = await requestsClient.setPassword(params);
    return res.setPassword;
  },
);

sample({
  clock: form.formValidated,
  source: login.stores.$loginData,
  filter: (data) => Boolean(data),
  fn: (data, values) => ({
    input: {
      userId: data.user.id,
      password: values.password,
    },
  }),
  target: setPasswordFx,
});

sample({
  clock: setPasswordFx.doneData,
  filter: Boolean,
  fn: () => EAuthStages.ENABLE_OTP,
  target: stage.events.setStage,
});

sample({
  clock: setPasswordFx.failData,
  fn: (e): IShowToast => ({
    status: EToastStatus.Error,
    messageKey: e.message as TLocaleKey,
  }),
  target: showToastEvent,
});

export const setPassword = {
  stores: {
    $pending: setPasswordFx.pending,
  },
  form,
};
