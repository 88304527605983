import {
  Box,
  Card as CardComponent,
  Flex,
  Heading,
  Separator,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICard } from './types';

export const Card: FC<ICard> = ({
  className = '',
  title,
  children,
  headerLeft,
  headerRight,
  headerBottom = null,
  headerDivider = false,
  props,
}) => {
  const { t } = useTranslation();
  return (
    <CardComponent.Root border='none'>
      {(title || headerLeft || headerRight || headerBottom) && (
        <>
          <CardComponent.Header paddingInline={0} paddingBlock={4}>
            {title && (
              <Heading textAlign='center' size='md'>
                {t(title)}
              </Heading>
            )}
            <Flex alignItems='center'>
              {headerLeft && <Box>{headerLeft}</Box>}
              {headerRight && <Box marginLeft='auto'>{headerRight}</Box>}
            </Flex>
            {headerBottom && <Box>{headerBottom}</Box>}
          </CardComponent.Header>
          {headerDivider && <Separator />}
        </>
      )}

      <CardComponent.Body minWidth='500px' {...props} className={className}>
        {children}
      </CardComponent.Body>
    </CardComponent.Root>
  );
};
