import { useForm } from 'effector-forms';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Flex,
  Heading,
  VStack,
  Button as ChakraButton,
} from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { authPagesModel } from 'pages/Auth/model';
import { EAuthStages } from 'pages/Auth/types';
import { TOnChange, TOnSubmit } from 'shared/config/types';
import { Button } from 'shared/ui/atoms/Button';
import { Input } from 'shared/ui/atoms/Input';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';
import { Text } from 'shared/ui/atoms/Text';
import { DEFAULT_FORM_GAP } from 'shared/config/constants';

export const LoginForm: React.FC = () => {
  const { t } = useTranslation();
  const { fields, submit, errorText } = useForm(authPagesModel.login.form);
  const pending = useUnit(authPagesModel.login.stores.$pending);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();

    submit();
  };

  const onForgotPassword = () => {
    authPagesModel.stage.events.setStage(EAuthStages.FORGOT_PASSWORD);
  };

  return (
    <Flex
      height='100%'
      position='relative'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <form onSubmit={onSubmit} style={{ width: 400 }}>
        <Heading textAlign='center' size='md' marginBottom={2}>
          {t('authorization.login.title')}
        </Heading>
        <Text
          color='secondary'
          props={{ textAlign: 'center', marginBottom: 8 }}>
          {t('authorization.login.welcomeBack')}
        </Text>
        <VStack gap={DEFAULT_FORM_GAP}>
          <Input
            props={{
              type: EInputFormatType.TEXT,
              inputProps: {
                name: fields.email.name,
                value: fields.email.value,
                onChange,
                placeholder: t('authorization.login.emailPlaceholder'),
                disabled: pending,
              },
            }}
            labelKey='authorization.login.email'
            errorKey={errorText('email')}
          />

          <Box position='relative' width='100%'>
            <Input
              props={{
                type: EInputFormatType.PASSWORD,
                inputProps: {
                  name: fields.password.name,
                  value: fields.password.value,
                  onChange,
                  placeholder: t('authorization.login.passwordPlaceholder'),
                  disabled: pending,
                },
              }}
              labelKey='authorization.login.password'
              errorKey={errorText('password')}
            />
            <ChakraButton
              position='absolute'
              top='-10px'
              right={0}
              padding={0}
              variant='plain'
              color='primary'
              onClick={onForgotPassword}>
              <Text
                props={{ textDecoration: 'underline' }}
                color='link'
                size='sm'>
                {t('authorization.login.forgotPassword')}
              </Text>
            </ChakraButton>
          </Box>
          <Button pending={pending} props={{ width: '100%' }} type='submit'>
            {t('authorization.login.loginBtn')}
          </Button>
        </VStack>
      </form>
      <Text
        color='secondary'
        props={{ position: 'absolute', bottom: 0 }}
        size='sm'>
        {t('copyright')}
      </Text>
    </Flex>
  );
};
