import { createForm } from 'effector-forms';
import {
  createEffect,
  createEvent,
  sample,
  forward,
  createStore,
} from 'effector';

import { rules } from 'shared/lib/rules';
import { requestsClient } from 'shared/api/client';
import {
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
} from 'shared/api/apollo/__generated__';

const forgotPasswordEvent = createEvent<ForgotPasswordMutationVariables>();
const forgotPasswordFx = createEffect<
  ForgotPasswordMutationVariables,
  ForgotPasswordMutation
>(requestsClient.forgotPassword);

sample({
  clock: forgotPasswordEvent,
  target: forgotPasswordFx,
});

const resetIsEmailLinkSend = createEvent();
const $isEmailLinkSend =
  createStore<boolean>(false).reset(resetIsEmailLinkSend);

const form = createForm({
  fields: {
    email: {
      init: '' as string,
      rules: [rules.requiredString(), rules.emailPattern()],
    },
  },
  validateOn: ['submit'],
});

sample({
  clock: form.formValidated,
  fn: ({ email }) => ({ input: { email } }),
  target: forgotPasswordEvent,
});

sample({
  clock: forgotPasswordFx.doneData,
  fn: () => true,
  target: $isEmailLinkSend,
});

export const forgotPassword = {
  form,
  stores: {
    $pending: forgotPasswordFx.pending,
    $isEmailLinkSend,
  },
  events: {
    forgotPasswordEvent,
    resetIsEmailLinkSend,
  },
};
