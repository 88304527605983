import { ErrorBoundary } from 'app/ErrorBoundary';
import { Route } from 'react-router-dom';
import { RouteGuard } from 'widgets/RouteGuard';
import { TRoute } from 'processes/Router/routes/types';

export const mapRoutes = (routes: TRoute, parentPath = '') =>
  routes &&
  Object.entries(routes).map(([key, route]) => {
    const { path, component, children, permissions, title, headerActions } =
      route;
    const formattedPath = (parentPath + '/' + path).replaceAll('//', '/');
    return (
      <Route
        key={key}
        path={path}
        element={
          <ErrorBoundary>
            <RouteGuard
              permissions={permissions}
              component={component}
              title={title}
              path={formattedPath}
              headerActions={headerActions}
            />
          </ErrorBoundary>
        }>
        {children && mapRoutes(children, formattedPath)}
      </Route>
    );
  });
