import { useField } from 'effector-forms';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@chakra-ui/react';
import { PIN_CODE_PATTERN } from 'entities/Auth/config';
import { authPagesModel } from 'pages/Auth/model';
import { Input } from 'shared/ui/atoms/Input';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';
import { Text } from 'shared/ui/atoms/Text';

export const ThirdStep: React.FC = () => {
  const { t } = useTranslation();
  const { value, onChange } = useField(
    authPagesModel.enableOtp.form.fields.pinCode,
  );

  const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    onChange(ev.target.value);
  };

  return (
    <Box>
      <Input
        props={{
          type: EInputFormatType.TEXT,
          mask: PIN_CODE_PATTERN,
          inputProps: {
            value: value,
            type: 'number',
            onChange: onInputChange,
          },
        }}
        labelKey='autentication.step3.label'
      />
      <Text size='sm'>{t('autentication.step3.inputDescription')}</Text>
    </Box>
  );
};
