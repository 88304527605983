import { useUnit } from 'effector-react';
import { useTranslation } from 'react-i18next';
import {
  MenuRoot,
  MenuItem,
  MenuContent,
  MenuTrigger,
} from 'components/ui/menu';
import { Box, Button, Flex, Icon } from '@chakra-ui/react';
import { profileModel } from 'entities/Profile/model';
import IconProfile from 'shared/assets/svg/navbar/icProfile.svg?react';
import IconSignOut from 'shared/assets/svg/navbar/icSignOut.svg?react';
import { Text } from 'shared/ui/atoms/Text';
import { logoutModal } from '../LogoutModal/model/modal';

export const Profile = () => {
  const { t } = useTranslation();
  const user = useUnit(profileModel.selfUser.stores.$selfUser);

  const logOutHandler = () => {
    logoutModal.setVisible(true);
  };

  return (
    <Flex alignItems='center' columnGap={2}>
      {user && (
        <Text
          color='inverse'
          size='sm'>{`${user.firstName} ${user.lastName}`}</Text>
      )}

      <MenuRoot closeOnSelect>
        <MenuTrigger>
          <Button variant='plain' as={Box} cursor='pointer'>
            <Icon color='white' width={6} height={6}>
              <IconProfile />
            </Icon>
          </Button>
        </MenuTrigger>
        <MenuContent>
          <MenuItem
            value='logout'
            paddingBlock={4}
            gap={4}
            onClick={logOutHandler}>
            <IconSignOut />
            <Text size='sm'>{t('leftSidebar.profile.buttonLogOut')}</Text>
          </MenuItem>
        </MenuContent>
      </MenuRoot>
    </Flex>
  );
};
