import { Box, List } from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { profileModel } from 'entities/Profile/model';
import { routes } from 'processes/Router/routes';
import { filterRoutesByPermissions } from 'widgets/Layout/lib/filterRoutesByPermissions';
import Dropdown from '../Dropdown';
import { NavItem } from '../NavItem';

export const Menu = () => {
  const selfPermissions = useUnit(
    profileModel.selfUser.stores.$selfPermissions,
  );
  return (
    <Box
      bg='surface.secondary'
      minWidth='250px'
      paddingInline={4}
      paddingBlock={2}
      borderRadius={12}
      height='fit-content'>
      <List.Root padding={0} margin={0} fontWeight={500} fontSize='md'>
        {Object.entries(routes['main'].children)
          .filter(
            ([_, route]) =>
              !route?.hide &&
              filterRoutesByPermissions(selfPermissions, route.permissions),
          )
          .map(([keyLabel, item], index) =>
            item.isDropdown ? (
              <Dropdown
                key={`Nav_Item_${index}`}
                route={item}
                keyLabel={keyLabel}
              />
            ) : (
              <NavItem
                key={`Nav_Item_${index}`}
                keyLabel={keyLabel}
                route={item}
              />
            ),
          )}
      </List.Root>
    </Box>
  );
};
