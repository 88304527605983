// Convert IP address to decimal (32-bit integer)
export const ipToDecimal = (ip: string): number => {
  const parts = ip.split('.').map(Number);
  return parts.reduce(
    (acc, part, index) => acc + (part << (8 * (3 - index))),
    0,
  );
};

// Convert CIDR notation to network and mask
export const parseCidr = (
  cidr: string,
): { network: string; prefix: number } => {
  const [network, prefix] = cidr.split('/');
  return { network, prefix: Number(prefix) };
};

// Check if a given gateway IP is within the network range defined by the CIDR
export const isIpInRange = (cidr: string, target: string): boolean => {
  // Parse the network IP and CIDR prefix
  const { network, prefix } = parseCidr(cidr);
  if (network === target) return false;
  // Convert network IP and gateway IP to decimal
  const networkDecimal = ipToDecimal(network);
  const gatewayDecimal = ipToDecimal(target);

  // Calculate the network range based on CIDR prefix
  const mask = ~(2 ** (32 - prefix) - 1); // Netmask based on CIDR
  const networkRangeStart = networkDecimal & mask; // Network start IP
  const networkRangeEnd = networkRangeStart + 2 ** (32 - prefix) - 1; // Network end IP

  // Check if gateway IP is within the network range
  return (
    gatewayDecimal >= networkRangeStart && gatewayDecimal <= networkRangeEnd
  );
};
