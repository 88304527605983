import { FC } from 'react';

import { Button as ButtonComponent } from '@chakra-ui/react';
import { ThreeDotsPending } from 'shared/ui/molecules/ThreeDotsPending';
import { colorConfig } from 'shared/ui/types';
import { IButtonProps } from './types';

export const Button: FC<IButtonProps> = ({
  id = '',
  formId,
  color = 'primary',
  children,
  type = 'button',
  isDisabled = false,
  variant = 'solid',
  onClick = () => {},
  onContextMenu = () => {},
  size = 'md',
  props,
  pending = false,
}) => {
  return (
    <ButtonComponent
      display='flex'
      alignItems='center'
      columnGap={2}
      borderRadius={12}
      variant={variant}
      colorPalette={colorConfig[color]}
      size={size}
      id={id}
      form={formId}
      type={type}
      data-size={size}
      onClick={onClick}
      onContextMenu={onContextMenu}
      disabled={pending || isDisabled}
      minWidth={100}
      {...props}>
      {pending ? <ThreeDotsPending /> : children}
    </ButtonComponent>
  );
};
