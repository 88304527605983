import { floatingIp } from './floatingIp';
import { notifications } from './notifications';
import { projects } from './projects';
import { quotas } from './quotas';

export const notificationsModel = {
  notifications,
  projects,
  quotas,
  floatingIp,
};
