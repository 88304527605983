import { css } from '@linaria/core';
import { theming } from 'shared/theme';

export const defaultWrapperClass = css`
  width: 32px;
  height: 32px;
  margin: auto;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const spinnerClass = css`
  animation: spin 1s linear infinite;
  transform-origin: center;
  color: #B6B9C9;
  fill: #3b83f6;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
