import { lazy } from 'react';
import { PERMISSIONS } from 'shared/config/constants';
import { TRoute } from './types';

export const routes: TRoute = {
  main: {
    path: '',
    title: 'leftSidebar.nav.users',
    children: {
      users: {
        path: 'users',
        permissions: [PERMISSIONS.USERS.SHOW_USERS],
        title: 'leftSidebar.nav.users',
        component: lazy(() =>
          import('pages/Users').then(({ Users }) => ({
            default: Users,
          })),
        ),
      },
      companies: {
        title: 'leftSidebar.nav.companies.title',
        permissions: [PERMISSIONS.COMPANIES.SHOW_COMPANIES],
        path: 'companies',
        component: lazy(() =>
          import('pages/Companies').then(({ Companies }) => ({
            default: Companies,
          })),
        ),
        children: {
          details: {
            path: ':name',
            permissions: [PERMISSIONS.COMPANIES.SHOW_COMPANY_DETAILS],
            component: lazy(() =>
              import('pages/CompanyDetails').then(({ CompanyDetails }) => ({
                default: CompanyDetails,
              })),
            ),
            title: 'leftSidebar.nav.companies.details',
            children: {
              summary: {
                path: 'summary',
                permissions: [PERMISSIONS.COMPANIES.SHOW_COMPANY_DETAILS],
                component: lazy(() =>
                  import('pages/CompanyCostSummary').then(
                    ({ CompanyCostSummary }) => ({
                      default: CompanyCostSummary,
                    }),
                  ),
                ),
                title: 'leftSidebar.nav.companies.summary',
              },
            },
          },
        },
      },
      company: {
        path: 'company',
        title: 'leftSidebar.nav.company',
        permissions: [PERMISSIONS.COMPANIES.SHOW_MY_COMPANY],
        component: lazy(() =>
          import('pages/MyCompany').then(({ MyCompany }) => ({
            default: MyCompany,
          })),
        ),
      },

      members: {
        path: 'members',
        permissions: [PERMISSIONS.MEMBERS.SHOW_MEMBERS],
        title: 'leftSidebar.nav.members',
        component: lazy(() =>
          import('pages/Members').then(({ Members }) => ({
            default: Members,
          })),
        ),
      },
      access: {
        path: 'access',
        permissions: [
          PERMISSIONS.PERMISSIONS.SHOW_PERMISSIONS,
          PERMISSIONS.ROLES.SHOW_ROLES,
          PERMISSIONS.SCOPES.SHOW_SCOPES,
        ],
        title: 'leftSidebar.nav.access.title',
        isDropdown: true,
        children: {
          scopes: {
            title: 'leftSidebar.nav.access.scopes',
            permissions: [PERMISSIONS.SCOPES.SHOW_SCOPES],
            path: 'scope',
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Scope').then(({ Scope }) => ({
                    default: Scope,
                  })),
                ),
                title: '',
              },
            },
          },
          roles: {
            title: 'leftSidebar.nav.access.roles',
            permissions: [PERMISSIONS.ROLES.SHOW_ROLES],
            path: 'role',
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Role').then(({ Role }) => ({
                    default: Role,
                  })),
                ),
                title: '',
              },
            },
          },
          permissions: {
            title: 'leftSidebar.nav.access.permissions',
            permissions: [PERMISSIONS.PERMISSIONS.SHOW_PERMISSIONS],
            path: 'permission',
            children: {
              table: {
                path: '',
                component: lazy(() =>
                  import('pages/Permission').then(({ Permission }) => ({
                    default: Permission,
                  })),
                ),
                title: '',
              },
            },
          },
        },
      },
      cloud: {
        title: 'leftSidebar.nav.cloud',
        permissions: [PERMISSIONS.NUTANIX.SHOW_CLOUD],
        path: 'cloud',
        component: lazy(() =>
          import('pages/Projects').then(({ Projects }) => ({
            default: Projects,
          })),
        ),
      },
      vms: {
        title: 'leftSidebar.nav.vms',
        permissions: [PERMISSIONS.NUTANIX.SHOW_VMS],
        path: 'vms',
        component: lazy(() =>
          import('pages/Vms').then(({ VmsTable }) => ({
            default: VmsTable,
          })),
        ),
      },
      resources: {
        path: 'resources',
        title: 'leftSidebar.nav.resources',
        permissions: [PERMISSIONS.RESOURCE.SHOW_RESOURCES],
        component: lazy(() =>
          import('pages/Resources').then(({ Resources }) => ({
            default: Resources,
          })),
        ),
      },
      billing: {
        path: 'billing',
        isDropdown: true,
        title: 'leftSidebar.nav.billing.title',
        permissions: [
          PERMISSIONS.RESOURCE_PRICINGS.SHOW_RESOURCE_PRICINGS,
          PERMISSIONS.INVOICES.SHOW_INVOICES,
        ],
        children: {
          resourcePricings: {
            path: 'resource-pricings',
            title: 'leftSidebar.nav.billing.resourcePricings',
            permissions: [PERMISSIONS.RESOURCE_PRICINGS.SHOW_RESOURCE_PRICINGS],
            component: lazy(() =>
              import('pages/ResourcePricings').then(({ ResourcePricings }) => ({
                default: ResourcePricings,
              })),
            ),
          },
          invoices: {
            path: 'invoices',
            title: 'leftSidebar.nav.billing.invoices',
            permissions: [PERMISSIONS.INVOICES.SHOW_INVOICES],
            component: lazy(() =>
              import('pages/Invoices').then(({ Invoices }) => ({
                default: Invoices,
              })),
            ),
          },
        },
      },
      summary: {
        path: 'summary',
        title: 'leftSidebar.nav.summary',
        permissions: [PERMISSIONS.COMPANIES.SHOW_MY_COMPANY],
        component: lazy(() =>
          import('pages/MyCompanyCostSummary').then(
            ({ MyCompanyCostSummary }) => ({
              default: MyCompanyCostSummary,
            }),
          ),
        ),
      },
      invoices: {
        path: 'invoices',
        title: 'leftSidebar.nav.invoices',
        permissions: [PERMISSIONS.INVOICES.SHOW_COMPANY_INVOICES],
        component: lazy(() =>
          import('pages/MyCompanyInvoices').then(({ MyCompanyInvoices }) => ({
            default: MyCompanyInvoices,
          })),
        ),
      },
      settings: {
        path: 'settings',
        permissions: [],
        title: 'leftSidebar.nav.settings',
        component: lazy(() =>
          import('pages/Settings').then(({ Settings }) => ({
            default: Settings,
          })),
        ),
      },
      notification: {
        path: 'notifications',

        hide: true,
        permissions: [],
        title: 'leftSidebar.nav.notifications',
        component: lazy(() =>
          import('pages/Notifications').then(({ Notifications }) => ({
            default: Notifications,
          })),
        ),
      },
    },
  },
};
