import { List } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Text } from 'shared/ui/atoms/Text';
import { leftSidebarIcons } from 'widgets/Layout/config';
import { parseToPathLink } from 'widgets/Layout/lib/parseToPathLink';
import { INavItem } from 'widgets/Layout/type';

export const NavItem: FC<INavItem> = ({ route, keyLabel }) => {
  const { t } = useTranslation();
  const renderLiItem = ({ isActive }) => (
    <List.Item
      display='flex'
      alignItems='center'
      userSelect='none'
      height={12}
      gap={2}
      paddingBlock={2}>
      {/* {leftSidebarIcons[keyLabel]} */}
      <Text color={isActive ? 'primary' : 'secondary'}> {t(route.title)}</Text>
    </List.Item>
  );
  return <NavLink to={parseToPathLink(route)}>{renderLiItem}</NavLink>;
};
