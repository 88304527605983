import { PaginationState } from '@tanstack/react-table';

export const RANGE_LENGTH = 'errors.rangeLength';

export const REQUIRED = 'errors.required';

// localStorage const
export const ACCESS_TOKEN_KEY = '@accessToken';
export const REFRESH_TOKEN_KEY = '@refreshToken';
export const HASHED_PASSWORD = '@hashedPassword';
export const TABLE_ENTRIES_PER_PAGE = '@tableEntriesPerPage';

export const SEARCH_DEFAULT_DEBOUNCE_TIME = 500;
export const SEARCH_DEBOUNCE_TIMEOUT = 250;

export const DISPLAY_DECIMALS = 2;

export const PERMISSIONS = {
  USERS: {
    GET_USERS: 'GET_USERS',
    GET_USER: 'GET_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    SHOW_USERS: 'SHOW_USERS',
  },
  MEMBERS: {
    GET_MEMBERS: 'GET_MEMBERS',
    SHOW_MEMBERS: 'SHOW_MEMBERS',
    CREATE_MEMBER: 'CREATE_MEMBER',
  },
  ROLES: {
    GET_ROLES: 'GET_ROLES',
    GET_ROLE: 'GET_ROLE',
    CREATE_ROLE: 'CREATE_ROLE',
    UPDATE_ROLE: 'UPDATE_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
    SHOW_ROLES: 'SHOW_ROLES',
  },
  RESOURCE_PRICINGS: {
    GET_RESOURCE_PRICINGS: 'GET_RESOURCE_PRICINGS',
    GET_RESOURCE_PRICING: 'GET_RESOURCE_PRICING',
    CREATE_RESOURCE_PRICING: 'CREATE_RESOURCE_PRICING',
    UPDATE_RESOURCE_PRICING: 'UPDATE_RESOURCE_PRICING',
    SHOW_RESOURCE_PRICINGS: 'SHOW_RESOURCE_PRICINGS',
    APPLY_RESOURCE_PRICING: 'APPLY_RESOURCE_PRICING',
  },
  RESOURCE: {
    GET_RESOURCES: 'GET_RESOURCES',
    GET_RESOURCE: 'GET_RESOURCE',
    CREATE_RESOURCE: 'CREATE_RESOURCE',
    UPDATE_RESOURCE: 'UPDATE_RESOURCE',
    SHOW_RESOURCES: 'SHOW_RESOURCES',
  },
  PERMISSIONS: {
    GET_PERMISSIONS: 'GET_PERMISSIONS',
    GET_PERMISSION: 'GET_PERMISSION',
    CREATE_PERMISSION: 'CREATE_PERMISSION',
    UPDATE_PERMISSION: 'UPDATE_PERMISSION',
    DELETE_PERMISSION: 'DELETE_PERMISSION',
    SHOW_PERMISSIONS: 'SHOW_PERMISSIONS',
  },
  SCOPES: {
    GET_SCOPES: 'GET_SCOPES',
    GET_SCOPE: 'GET_SCOPE',
    CREATE_SCOPE: 'CREATE_SCOPE',
    UPDATE_SCOPE: 'UPDATE_SCOPE',
    SHOW_SCOPES: 'SHOW_SCOPES',
  },
  PROFILE: {
    GET_USERS: 'GET_USERS',
    GET_USER: 'GET_USER',
    CREATE_USER: 'CREATE_USER',
    UPDATE_USER: 'UPDATE_USER',
    BLOCK_USER: 'BLOCK_USER',
    RESTORE_USER: 'RESTORE_USER',
  },
  NUTANIX: {
    GET_VMS: 'GET_VMS',
    GET_PROJECTS: 'GET_PROJECTS',
    CREATE_PROJECT: 'CREATE_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    REMOVE_PROJECT: 'REMOVE_PROJECT',
    SHOW_CLOUD: 'SHOW_CLOUD',
    SHOW_VMS: 'SHOW_VMS',
    ASSIGN_FLOATING_IP: 'ASSIGN_FLOATING_IP',
    CLEAR_FLOATING_IP: 'CLEAR_FLOATING_IP',
  },
  COMPANIES: {
    GET_COMPANIES: 'GET_COMPANIES',
    GET_COMPANY: 'GET_COMPANY',
    CREATE_COMPANY: 'CREATE_COMPANY',
    SHOW_COMPANIES: 'SHOW_COMPANIES',
    SHOW_MY_COMPANY: 'SHOW_MY_COMPANY',
    SHOW_COMPANY_DETAILS: 'SHOW_COMPANY_DETAILS',
  },
  QUOTAS: {
    GET_QUOTAS: 'GET_QUOTAS',
    GET_QUOTA: 'GET_QUOTA',
    SHOW_QUOTAS: 'SHOW_QUOTAS',
    CREATE_QUOTA: 'CREATE_QUOTA',
    SUBMIT_QUOTA: 'SUBMIT_QUOTA',
    APPROVE_QUOTA: 'APPROVE_QUOTA',
    REJECT_QUOTA: 'REJECT_QUOTA',
  },
  INVOICES: {
    SHOW_INVOICES: 'SHOW_INVOICES',
    SHOW_COMPANY_INVOICES: 'SHOW_COMPANY_INVOICES',
    GET_INVOICES: 'GET_INVOICES',
    SET_INVOICE_PAID: 'SET_INVOICE_PAID',
    SET_INVOICE_CANCELED: 'SET_INVOICE_CANCELED',
  },
  TEST: {
    TEST: 'TEST',
  },
};

export const STORAGE_KEYS = {
  USERS_FILTERS: 'usersFilters',
  ROLES_FILTERS: 'rolesFilters',
  TRANSACTION_FILTERS: 'transactionFilters',
};

export const FILTER_TYPES = {
  PERMISSION_IDS: 'permissionIds',
  ROLE_IDS: 'roleIds',
};

export const paginationInitial: PaginationState = {
  pageIndex: 0,
  pageSize: 20,
};

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const ACCESS_TOKEN_EXPIRED = 'ACCESS_TOKEN_EXPIRED';

export const MAX_DEFAULT_VCPU = 512;
export const MAX_DEFAULT_MEMORY = 1024;
export const MAX_DEFAULT_DISK = 10000;
export const MAX_DEFAULT_PUBLIC_IPS = 8;

export const MIN_DEFAULT_VCPU = 1;
export const MIN_DEFAULT_MEMORY = 1;
export const MIN_DEFAULT_DISK = 10;
export const MIN_DEFAULT_INTERNET = 1;
export const MIN_DEFAULT_PUBLIC_IPS = 1;

export const DEFAULT_FORM_GAP = 6;
