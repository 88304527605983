import { enableOtp } from './enableOtp';
import { verifyOtp } from './verifyOtp';
import { generateOtp } from './generateOtp';
import { otpSteps } from './otpSteps';
import { device } from './device';
import { login } from './login';
import { setPassword } from './setPassword';
import { stage } from './stage';
import { forgotPassword } from './forgotPassword';

export const authPagesModel = {
  otpSteps,
  generateOtp,
  enableOtp,
  verifyOtp,
  device,
  login,
  setPassword,
  forgotPassword,
  stage,
};
