import { createStore, sample } from 'effector';
import { INotification } from '../types';
import { cacheFactory } from 'shared/lib/cacheFactory';
import { CACHE_KEY_NOTIFICATIONS } from 'shared/config/cache';
import { projects } from './projects';
import { quotas } from './quotas';
import { floatingIp } from './floatingIp';

const $notifications = createStore<INotification[]>([]);
const $sortedNotifications = createStore<INotification[]>([]);

const {
  initCache: initNotificationsCache,
  resetCache: resetNotificationCache,
} = cacheFactory({
  $store: $notifications,
  cacheKey: CACHE_KEY_NOTIFICATIONS,
  isPureStore: true,
});

const $notificationsCount = createStore<number>(0).reset(
  resetNotificationCache,
);

sample({
  clock: projects.events.addProjectNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: quotas.events.addQuotaNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: floatingIp.events.addFloatingIpNotification,
  source: $notifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $notifications,
});

sample({
  clock: $notifications,
  fn: (notifications) => notifications?.length ?? 0,
  target: $notificationsCount,
});

sample({
  clock: $notifications,
  fn: (notifications) =>
    notifications.sort((a, b) => b.timestamp - a.timestamp),
  target: $sortedNotifications,
});

export const notifications = {
  stores: { $notifications, $sortedNotifications, $notificationsCount },
  events: {
    initNotificationsCache,
    resetNotificationCache,
  },
};
