export enum endPoints {
  DEFAULT_PAGE = '/',
  AUTHORIZATION_PAGE = '*',

  RESET_PASSWORD = '/reset-password',

  CONFIRM_EMAIL = 'confirm-email',

  ROLE = '/access/role',

  SCOPE = '/access/scope',

  PERMISSION = '/access/permission',

  USER = '/users',

  CLOUD = '/cloud',

  VMS = '/vms',

  COMPANIES = '/companies',
  COMPANY_DETAILS = '/companies/:name',
  COMPANY_COST_SUMMARY = '/companies/:name/summary',

  MY_COMPANY = '/company',
  SUMMARY = '/summary',

  NOTIFICATIONS = '/notifications',
  SETTINGS = '/settings',
  CHANGE_PASSWORD = '/settings/change-password',
}
