import { LogoutModal } from '../LogoutModal';
import { Menu } from '../Menu';

export const LeftSidebar = () => {
  return (
    <>
      <Menu />
      <LogoutModal />
    </>
  );
};
