import { createEvent, createStore, sample } from 'effector';
import { INotification } from '../types';

const addFloatingIpNotification = createEvent<INotification>();
const $floatingIpNotifications = createStore<INotification[]>([]);

sample({
  clock: addFloatingIpNotification,
  source: $floatingIpNotifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $floatingIpNotifications,
});

export const floatingIp = {
  stores: {
    $floatingIpNotifications,
  },
  events: { addFloatingIpNotification },
};
