import { Text as TextComponent } from '@chakra-ui/react';
import { FC } from 'react';
import { IText } from './types';

export const Text: FC<IText> = ({
  id,
  title,
  color = 'primary',
  size = 'md',
  props,
  children,
}) => {
  return (
    <TextComponent
      color={`text.${color}`}
      fontSize={size}
      id={id}
      title={title}
      {...props}>
      {children}
    </TextComponent>
  );
};
