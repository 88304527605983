import { ReactNode } from 'react';
import { TLocaleKey } from 'shared/locales/i18n';

export enum EToastStatus {
  Error = 'error',
  Info = 'info',
  Loading = 'loading',
  Success = 'success',
}

interface ITranslateParams {
  [key: string]: string;
}

export interface IShowToast {
  duration?: number;
  status: EToastStatus;
  messageKey: string;
  translateParams?: ITranslateParams;
}

export type TShowToast = (params: IShowToast) => ReactNode;
