import { toaster } from 'components/ui/toaster';
import i18next from 'shared/locales/i18n';
import { EToastStatus, TShowToast } from '../types';

export const showToast: TShowToast = ({
  messageKey,
  translateParams,
  status,
  duration = 3000,
}) => {
  if (status === EToastStatus.Success)
    return toaster.create({
      duration,
      title: i18next.t(messageKey, translateParams),
      type: 'success',
    });

  if (status === EToastStatus.Error)
    return toaster.create({
      duration,
      title: i18next.t(messageKey, translateParams),
      type: 'error',
    });

  if (status === EToastStatus.Loading)
    return toaster.create({
      duration,
      title: i18next.t(messageKey, translateParams),
      type: 'loading',
    });

  if (status === EToastStatus.Info)
    return toaster.create({
      duration,
      title: i18next.t(messageKey, translateParams),
      type: 'info',
    });
};
