import { useForm } from 'effector-forms';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { PIN_CODE_PATTERN } from 'entities/Auth/config';
import { TOnChange } from 'shared/config/types';
import { Input } from 'shared/ui/atoms/Input';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';
import { IOtpCodeForm } from './types';

export const OTPCodeForm: FC<IOtpCodeForm> = ({ form, pending = false }) => {
  const { t } = useTranslation();
  const { fields, errorText, reset } = useForm(form);

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  return (
    <Input
      props={{
        type: EInputFormatType.TEXT,
        onlyNumbers: true,
        mask: PIN_CODE_PATTERN,
        inputProps: {
          placeholder: t('otpCodeModal.codePlaceholder'),
          name: fields.pinCode.name,
          value: fields.pinCode.value,
          type: 'number',
          onChange,
          disabled: pending,
          autoFocus: true,
        },
      }}
      labelKey='otpCodeModal.label'
      helperKey='otpCodeModal.description'
      errorKey={errorText('pinCode')}
    />
  );
};
