import { styled } from '@linaria/react';
import { theming } from 'shared/theme';
import { ISpinnerBlock } from './types';

export const StyledSpinnerBlock = styled.div<ISpinnerBlock>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;

  & > .spinner {
    position: relative;
    text-indent: -9999em;
    border-top: ${({ size }) => size * 0.11}px solid;
    border-right: ${({ size }) => size * 0.11}px solid;
    border-bottom: ${({ size }) => size * 0.11}px solid;
    border-left: ${({ size }) => size * 0.11}px solid;
    border-top-color: #B6B9C9;
    border-right-color: #B6B9C9;
    border-bottom-color: #B6B9C9;
    border-left-color: #3b83f6;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    border-radius: 50%;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;

    &:after {
      border-radius: 50%;
      width: ${({ size }) => size}px;
      height: ${({ size }) => size}px;
    }

    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
`;
