import { ApolloLink, fromPromise, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import {
  ACCESS_TOKEN_EXPIRED,
  ACCESS_TOKEN_KEY,
  HASHED_PASSWORD,
  REFRESH_TOKEN_KEY,
  UNAUTHORIZED,
} from 'shared/config/constants';
import { requestsClient } from '.';

let authToken = localStorage.getItem(ACCESS_TOKEN_KEY);

const httpLink = new HttpLink({
  uri: `${import.meta.env.VITE_GRAPHQL_URL}`,
});

const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Number.POSITIVE_INFINITY,
  },
  attempts: {
    max: 1,
    retryIf: () => true,
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: authToken ? `Bearer ${authToken}` : '',
      timeout: 999999,
    },
  });
  return forward(operation);
});

export const updateAuthToken = (newToken: string) => {
  authToken = newToken;
  localStorage.setItem(ACCESS_TOKEN_KEY, newToken);
};

const getNewToken = () => {
  return requestsClient
    .refreshTokens({
      input: {
        refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
      },
    })
    .then((response) => response.refreshTokens);
};

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      switch (err.message) {
        case UNAUTHORIZED:
        case ACCESS_TOKEN_EXPIRED: {
          return fromPromise(
            getNewToken()
              .then((response) => {
                const accessToken = response.accessToken;
                const refreshToken = response.refreshToken;

                updateAuthToken(accessToken); // Update in-memory token
                localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${accessToken}`,
                  },
                }));

                return forward(operation);
              })
              .catch(async (error) => {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                localStorage.removeItem(REFRESH_TOKEN_KEY);
                localStorage.removeItem(HASHED_PASSWORD);
                window.location.reload();
                throw error;
              }),
          ).flatMap(() => forward(operation));
        }
      }
    }
  }
});

export { authMiddleware, errorLink, httpLink, retryLink };
