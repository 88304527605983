import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@chakra-ui/react';
import { IStepperItem } from './types';

export const StepperItem: React.FC<IStepperItem> = ({
  titleKey,
  isActive = false,
  number,
}) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection='column' alignItems='center'>
      <Flex
        alignItems='center'
        justifyContent='center'
        width={26}
        height={26}
        borderRadius='50%'
        marginBottom={1}
        fontWeight={600}
        fontSize={12}
        color='white'
        bg={isActive ? 'primary' : 'gray.500'}
        data-isactive={isActive}>
        {number}
      </Flex>

      <Text
        fontSize={12}
        fontWeight={500}
        marginTop={2}
        color={isActive ? 'text.primary' : 'text.secondary'}>
        {t(titleKey)}
      </Text>
    </Flex>
  );
};
