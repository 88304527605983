import { createEvent, createStore, sample } from 'effector';
import { INotification } from '../types';

const addQuotaNotification = createEvent<INotification>();
const $quotaNotifications = createStore<INotification[]>([]);

sample({
  clock: addQuotaNotification,
  source: $quotaNotifications,
  fn: (notifications, notification) => [...notifications, notification],
  target: $quotaNotifications,
});

export const quotas = {
  stores: {
    $quotaNotifications,
  },
  events: { addQuotaNotification },
};
