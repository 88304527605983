import React from 'react';
import { useTranslation } from 'react-i18next';

import { EInputFormatType, IInput } from './types';

import { Spinner, Input as TextInput } from '@chakra-ui/react';
import { Field } from 'components/ui/field';
import { InputGroup } from 'components/ui/input-group';
import { NumberInputField, NumberInputRoot } from 'components/ui/number-input';
import { PasswordInput } from 'components/ui/password-input';
import { withMask } from 'use-mask-input';

const defaultStyles = {
  width: '100%',
  border: '2px solid',
  borderColor: '#e9eaea',
  borderRadius: 12,
  _focus: { outlineColor: 'blue.300' },
  _invalid: { outlineColor: 'red.300' },
  background: 'surface.primary',
};

export const Input: React.FC<IInput> = ({
  labelKey,
  errorKey,
  helperKey,
  rightContent,
  leftContent,
  pending = false,
  props = {
    type: EInputFormatType.TEXT,
    inputProps: {},
  },
}) => {
  const { t } = useTranslation();
  return (
    <Field
      width='100%'
      invalid={Boolean(errorKey)}
      errorText={t(errorKey)}
      helperText={t(helperKey)}
      label={t(labelKey)}>
      <InputGroup
        width='100%'
        startElement={leftContent}
        endElement={
          (pending || rightContent) && (
            <>
              {pending && <Spinner width={4} height={4} />}
              {rightContent}
            </>
          )
        }>
        {props.type === EInputFormatType.TEXT ? (
          <TextInput
            {...props.inputProps}
            {...defaultStyles}
            type='text'
            inputMode={props.onlyNumbers ? 'numeric' : undefined}
            // pattern={props.onlyNumbers ? '\\d*' : undefined}
            ref={props.mask ? withMask('999 999') : props.inputProps.ref}
          />
        ) : props.type === EInputFormatType.PASSWORD ? (
          <PasswordInput {...props.inputProps} {...defaultStyles} />
        ) : (
          <NumberInputRoot
            width='100%'
            ref={props.mask ? withMask(props.mask) : props.inputProps.ref}>
            <NumberInputField {...props.inputProps} {...defaultStyles} />
          </NumberInputRoot>
        )}
      </InputGroup>
    </Field>
  );
};
