import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { Provider } from 'components/ui/provider';
import { Toaster } from 'components/ui/toaster';
import { authModel } from 'entities/Auth/model';
import { Auth } from 'pages/Auth';
import { ConfirmEmail } from 'pages/ConfirmEmail';
import { cacheModel } from 'processes/Cache/model';
import { endPoints } from 'processes/Router/endPoints';
import { routes } from 'processes/Router/routes';
import { ETheme } from 'shared/config/enums';
import { QueryCache } from 'shared/lib/queryCache/QueryCache';
import { globalStyles } from 'shared/theme/GlobalStyles';
import { SpinnerBlock } from 'shared/ui/molecules/SpinnerBlock';
import Layout from 'widgets/Layout';
import { mapRoutes } from 'widgets/RouteGuard/lib/mapRoutes';
import { ErrorBoundary } from './ErrorBoundary';
import './fonts.css';
import './globals.css';
import { useSocket } from './lib/useSocket';

const clearLocalStorage = () => {
  QueryCache.clearCache();
};

const App = () => {
  const [isAuth, pending] = useUnit([
    authModel.isAuth.stores.$isAuth,
    authModel.isAuth.stores.$pending,
  ]);
  useSocket();

  useEffect(() => {
    window.addEventListener('beforeunload', clearLocalStorage);
    return () => {
      window.removeEventListener('beforeunload', clearLocalStorage);
    };
  }, []);
  useEffect(() => {
    authModel.isAuth.events.initAuth();
  }, []);

  useEffect(() => {
    if (isAuth) cacheModel.init.events.initialize();
    else cacheModel.init.events.reset();
  }, [isAuth]);

  return (
    <Provider>
      <Toaster />
      <ErrorBoundary>
        {pending ? (
          <SpinnerBlock size={60} />
        ) : (
          <Box
            className={`${globalStyles} ${ETheme.light}`}
            display='flex'
            minWidth='100vw'
            minHeight='100vh'
            justifyContent='center'
            alignItems='center'>
            <Routes>
              <Route
                path={endPoints.CONFIRM_EMAIL}
                element={
                  <ErrorBoundary>
                    <ConfirmEmail />
                  </ErrorBoundary>
                }
              />
              {isAuth ? (
                <Route path={endPoints.DEFAULT_PAGE} element={<Layout />}>
                  {mapRoutes(routes)}
                  <Route
                    index
                    element={
                      <ErrorBoundary>
                        <Navigate to='/users' />
                      </ErrorBoundary>
                    }
                  />
                </Route>
              ) : (
                <>
                  <Route
                    path={endPoints.AUTHORIZATION_PAGE}
                    element={
                      <ErrorBoundary>
                        <Auth />
                      </ErrorBoundary>
                    }
                  />
                </>
              )}
            </Routes>
          </Box>
        )}
      </ErrorBoundary>
    </Provider>
  );
};

export default App;
