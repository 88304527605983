import { createEffect, createEvent, sample } from 'effector';
import { otpFormFactory } from 'entities/Auth/lib/otpFormFactory';
import { authModel } from 'entities/Auth/model';
import {
  LoginType,
  VerifyOtpMutationVariables,
  VerifyOtpResponse,
} from 'shared/api/apollo/__generated__';
import { requestsClient } from 'shared/api/client';
import { updateAuthToken } from 'shared/api/client/links';
import { REFRESH_TOKEN_KEY } from 'shared/config/constants';
import { GLOBAL_SPACE } from 'shared/config/regulars';
import { modalFactory } from 'shared/lib/modalFactory';
import { toastEffect } from 'shared/lib/toastEffect';
import { login } from './login';

const modal = modalFactory(false);
const form = otpFormFactory();

const verifyOTP = createEvent<VerifyOtpMutationVariables>();
const verifyOTPFx = toastEffect(
  createEffect<VerifyOtpMutationVariables, VerifyOtpResponse>(
    async (params) => {
      const res = await requestsClient.verifyOtp(params);
      updateAuthToken(res.verifyOtp.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, res.verifyOtp.refreshToken);
      return res.verifyOtp;
    },
  ),
  {
    errorMessage: 'authorization.errors.invalidCode',
  },
);

sample({
  clock: verifyOTP,
  target: verifyOTPFx,
});

sample({
  clock: login.stores.$loginData,
  filter: (data) => data.type === LoginType.VerifyOtp,
  fn: () => true,
  target: modal.setVisible,
});

sample({
  clock: form.formValidated,
  source: login.stores.$loginData,
  fn: (data, form) => ({
    input: {
      token: form.pinCode.replace(GLOBAL_SPACE, ''),
      userId: data.user.id,
    },
  }),
  target: verifyOTPFx,
});

sample({
  clock: verifyOTPFx.doneData,
  target: authModel.isAuth.events.setAuth,
});

export const verifyOtp = {
  events: {
    verifyOTP,
  },
  stores: {
    $pending: verifyOTPFx.pending,
  },
  form,
  modal,
};
