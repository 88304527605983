import { createSystem, defaultConfig } from '@chakra-ui/react';
import { DefaultTheme } from './type';

export const system = createSystem(defaultConfig, {
  globalCss: {
    body: {
      bg: 'white',
    },
  },
  theme: {
    recipes: {
      checkBox: {
        base: {
          borderRadius: '50%',
        },
      },
      button: {
        base: {
          transition: 'all 0.3s',
        },
        variants: {
          colorPalette: {
            red: {
              bg: 'red.400',
              color: '{colors.text.inverse}',
              _hover: {
                bg: 'red.500',
              },
            },
            gray: {
              bg: 'gray.300',
              color: '{colors.text.primary}',
              _hover: {
                bg: 'gray.400',
              },
            },
            blue: {
              bg: 'blue.500',
              color: '{colors.text.inverse}',
              _hover: {
                bg: 'blue.600',
              },
            },
          },
        },
      },
      badge: {
        variants: {
          colorPalette: {
            gray: {
              bg: 'gray.300',
              color: '{colors.text.primary}',
              fontSize: 'xs',
              fontWeight: 500,
            },
            blue: {
              bg: 'blue.500',
              color: '{colors.text.inverse}',
              fontSize: 'xs',
              fontWeight: 500,
            },
            red: {
              bg: 'red.400',
              color: '{colors.text.inverse}',
              fontSize: 'xs',
              fontWeight: 500,
            },
            green: {
              bg: 'green.600',
              color: '{colors.text.inverse}',
            },
            orange: {
              bg: 'orange.600',
              color: '{colors.text.inverse}',
            },
          },
        },
      },
    },
    tokens: {
      colors: {
        gray: {
          50: { value: '#f9f9f9' }, // Lightest
          100: { value: '#f8f9fa' },
          200: { value: '#e9ecef' },
          300: { value: '#dee2e6' },
          400: { value: '#ced4da' },
          500: { value: '#adb5bd' }, // Base
          600: { value: '#6c757d' },
          700: { value: '#495057' },
          800: { value: '#343a40' },
          900: { value: '#212529' }, // Darkest
        },
        blue: {
          50: { value: '#d6f2ff' }, // Lightest
          100: { value: '#ade5ff' },
          200: { value: '#85d7ff' },
          300: { value: '#5ccaff' },
          400: { value: '#33bcff' },
          500: { value: '#079cff' }, // Base
          600: { value: '#0686e0' },
          700: { value: '#056fbf' },
          800: { value: '#04599e' },
          900: { value: '#03407a' }, // Darkest
        },
        green: {
          50: { value: '#e9f8f0' }, // Lightest
          100: { value: '#c6eeda' },
          200: { value: '#a3e3c4' },
          300: { value: '#80d8ad' },
          400: { value: '#5dcd97' },
          500: { value: '#27ae60' }, // Base
          600: { value: '#209650' },
          700: { value: '#197c41' },
          800: { value: '#126331' },
          900: { value: '#0b4922' }, // Darkest
        },
        orange: {
          50: { value: '#fff9e6' }, // Lightest
          100: { value: '#ffecb3' },
          200: { value: '#ffdf80' },
          300: { value: '#ffd34d' },
          400: { value: '#ffc71a' },
          500: { value: '#ffb300' }, // Base
          600: { value: '#e09f00' },
          700: { value: '#bf8900' },
          800: { value: '#9e7300' },
          900: { value: '#7a5a00' }, // Darkest
        },
        red: {
          50: { value: '#ffe5e5' }, // Lightest
          100: { value: '#fcb8b8' },
          200: { value: '#f78a8a' },
          300: { value: '#f25c5c' },
          400: { value: '#ed2e2e' },
          500: { value: '#d90000' }, // Base
          600: { value: '#a11d33' },
          700: { value: '#8c0000' },
          800: { value: '#660000' },
          900: { value: '#400000' }, // Darkest
        },
      },
    },
    semanticTokens: {
      colors: {
        primary: { value: '{colors.blue.500}' }, // Maps to blue (base)
        primaryLight: { value: '{colors.blue.100}' }, // Lighter shade
        primaryDark: { value: '{colors.blue.700}' }, // Darker shade

        secondary: { value: '{colors.gray.500}' }, // Maps to gray (base)
        secondaryLight: { value: '{colors.gray.100}' }, // Lighter shade
        secondaryDark: { value: '{colors.gray.700}' }, // Darker shade

        error: { value: '{colors.red.500}' }, // Maps to red (base)
        errorLight: { value: '{colors.red.100}' }, // Lighter shade
        errorDark: { value: '{colors.red.700}' }, // Darker shade

        warning: { value: '{colors.orange.500}' }, // Maps to orange (base)
        warningLight: { value: '{colors.orange.100}' }, // Lighter shade
        warningDark: { value: '{colors.orange.700}' }, // Darker shade

        success: { value: '{colors.green.500}' }, // Maps to green (base)
        successLight: { value: '{colors.green.100}' }, // Lighter shade
        successDark: { value: '{colors.green.700}' }, // Darker shade

        surface: {
          primary: { value: '#FFFFFF' },
          secondary: { value: '#F4F5F7' },
          gradient: {
            value: 'linear-gradient(270deg, #07BAFE -2.66%, #0172F5 131.72%)',
          },
        },
        text: {
          primary: { value: '#1F1F27' },
          secondary: { value: '#616C78' },
          header: { value: '#24282E' },
          inverse: { value: '#FFFFFF' },
          link: { value: '#329AFB' },
          success: { value: '#27ae60' },
          danger: { value: '#d90000' },
          warning: { value: '#ffb300' },
        },
        divider: {
          primary: { value: '#E9EAEA' },
        },
      },
    },
  },
});

export const lightTheme: DefaultTheme = {
  spinner: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  search: {
    container: {
      boxShadow:
        '0px 1px 2px rgba(128,138,157,.12),0px 8px 32px rgba(128,138,157,.24);',
    },
    input: {
      bg: '#eff2f5',
    },
  },
  transactions: {
    stats: {
      graph: {
        tooltipFilter:
          'drop-shadow(0px 4.745809555053711px 9.491619110107422px rgba(50, 50, 71, 0.06)) drop-shadow(0px 4.745809555053711px 4.745809555053711px rgba(50, 50, 71, 0.08))',
      },
    },
  },
  leftSidebar: {
    input: {
      border: '#636566',
      color: '#FFFFFF',
      placeholder: '#636566',
    },
    nav: {
      dropdown: {
        arrowColor: '#FFFFFF',
      },
      active: {
        background: '#0000001a',
      },
      default: {
        background: '#FFFFFF',
      },
    },
    textColor: '#636566',
  },
  modalBoxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
  commonBackground: {
    bg1: '#1A1C1E',
    bg2: '#FFFFFF',
    bg3: '#e4e4e4',
  },
  text: {
    secondary: '#616C78',
    primary: '#1F1F27',
    header: '#24282E',
  },
  backgrounds: {
    layout: '#F6F6F6',
    row: {
      head: '#E7ECFD',
      body: '#F2F2F2',
    },
  },
  colors: {
    grey: '#81869A',
    blue: '#329AFB',
    white: '#FFFFFF',
    black: '#000000',
  },
  link: {
    color: '#3b83f6',
  },
  button: {
    primary: {
      default: {
        background: '#3b83f6',
        color: '#FFFFFF',
      },
      hover: {
        background: '#5a9fff',
      },
      disabled: {
        background: '#a3bffb',
      },
    },
    secondary: {
      default: {
        background: '#e9edf3',
        color: '#1f2937',
      },
      hover: {
        background: '#d3d8e1',
      },
      disabled: {
        background: '#c5cbd3',
        color: '#a1a7af',
      },
    },
    warning: {
      default: {
        background: '#EB5757',
        color: '#FFFFFF',
        border: '#EB5757',
      },
      hover: {
        background: '#f06e6e',
      },
      disabled: {
        background: '#ed8e8e',
      },
    },
  },
  radioButton: {
    color: '#B6B9C9',
  },
  error: {
    color: '#FA4C4C',
    background: '#F1E7E4',
    borderColor: '#FA4C4C',
    boxShadow: 'inset 3px 3px 10px rgba(187, 79, 44, 0.2)',
  },
  input: {
    default: {
      borderColor: '#B8B8B8',
      labelColor: '#1F1F27',
    },
    hover: {
      borderColor: '#E5E7E9',
    },
    focus: {
      borderColor: '#3b83f6',
      labelColor: '#3b83f6',
    },
    disabled: {
      borderColor: '#c5cbd3',
      labelColor: '#c5cbd3',
    },
    error: {
      borderColor: '#EB5757',
      labelColor: '#EB5757',
      iconColor: '#EB5757',
    },
    textColor: '#1A1C1E',
    placeholderColor: '#B8B8B8',
    iconBackground: '#81869A',
  },
  select: {
    default: {
      color: '#B8B8B8',
      borderColor: '#B8B8B8',
    },
    disabled: {
      color: '#c5cbd3',
      borderColor: '#c5cbd3',
    },
    focus: {
      color: '#3b83f6',
      borderColor: '#3b83f6',
    },
    error: {
      borderColor: '#EB5757',
      color: '#EB5757',
    },
    tile: {
      hover: 'rgba(152, 170, 228, 0.12)',
      active: 'rgba(54, 97, 235, 0.12)',
    },
    placeholderColor: '#B8B8B8',
    textColor: '#1A1C1E',
  },
  checkbox: {
    active: {
      background: '#3b83f6',
    },
    inactive: {
      background: '#c5cbd3',
    },
    markColor: '#FFFFFF',
  },
  switch: {
    active: {
      background: '#3b83f6',
      circle: '#FFFFFF',
    },
    inactive: {
      background: '#c5cbd3',
      circle: '#FFFFFF',
    },
  },
  table: {
    buttonColor: '#3b83f6',
    buttonTextColor: '#FFFFFF',
    buttonDisabledColor: '#B8B8B8',
    tableTrOdd: '#f4f4f4',
    tableText: '#1A1C1E',
  },
  toast: {
    success: {
      background: 'rgba(39, 174, 96, 0.12)',
      color: '#27ae60',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    error: {
      background: 'rgba(235, 87, 87, 0.12)',
      color: '#eb5757',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    warning: {
      background: 'rgba(242, 153, 74, 0.12)',
      color: '#f2994a',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    info: {
      background: 'rgba(54, 97, 235, 0.12)',
      color: '#3b83f6',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
  },
  authentication: {
    description: '#888888',
    text: '#FFFFFF',
    stepperBg: 'rgba(136, 136, 136, 0.2)',
    stepperBgActive: '#3b83f6',
    textInactiveStepper: 'rgba(0, 0, 0, 0.4)',
    cardBackground: 'rgba(255, 255, 255, 0.4)',
  },
};

export const darkTheme: DefaultTheme = {
  spinner: {
    color: 'rgba(255, 255, 255, 0.2)',
  },
  search: {
    container: {
      boxShadow:
        '0px 1px 2px rgba(128,138,157,.12),0px 8px 32px rgba(128,138,157,.24);',
    },
    input: {
      bg: '#eff2f5',
    },
  },
  transactions: {
    stats: {
      graph: {
        tooltipFilter:
          'drop-shadow(0px 4.745809555053711px 9.491619110107422px rgba(50, 50, 71, 0.06)) drop-shadow(0px 4.745809555053711px 4.745809555053711px rgba(50, 50, 71, 0.08))',
      },
    },
  },
  leftSidebar: {
    input: {
      border: '#636566',
      color: '#FFFFFF',
      placeholder: '#636566',
    },
    nav: {
      dropdown: {
        arrowColor: '#FFFFFF',
      },
      active: {
        background: '#0000001a',
      },
      default: {
        background: '#FFFFFF',
      },
    },
    textColor: '#636566',
  },
  modalBoxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
  commonBackground: {
    bg1: '#1A1C1E',
    bg2: '#FFFFFF',
    bg3: '#e4e4e4',
  },
  text: {
    primary: '#1F1F27',
    header: '#24282E',
    secondary: '#616C78',
  },
  backgrounds: {
    layout: '#F6F6F6',
    row: {
      head: '#E7ECFD',
      body: '#F2F2F2',
    },
  },
  colors: {
    grey: '#81869A',
    blue: '#329AFB',
    white: '#FFFFFF',
    black: '#000000',
  },
  link: {
    color: '#3b83f6',
  },
  button: {
    primary: {
      default: {
        background: '#3b83f6',
        color: '#FFFFFF',
      },
      hover: {
        background: '#5a9fff',
      },
      disabled: {
        background: '#a3bffb',
      },
    },
    secondary: {
      default: {
        background: '#e9edf3',
        color: '#1f2937',
      },
      hover: {
        background: '#d3d8e1',
      },
      disabled: {
        background: '#c5cbd3',
        color: '#a1a7af',
      },
    },
    warning: {
      default: {
        background: '#EB5757',
        color: '#FFFFFF',
        border: '#EB5757',
      },
      hover: {
        background: '#f06e6e',
      },
      disabled: {
        background: '#ed8e8e',
      },
    },
  },
  radioButton: {
    color: '#B6B9C9',
  },
  error: {
    color: '#FA4C4C',
    background: '#F1E7E4',
    borderColor: '#FA4C4C',
    boxShadow: 'inset 3px 3px 10px rgba(187, 79, 44, 0.2)',
  },
  input: {
    default: {
      borderColor: '#B8B8B8',
      labelColor: '#1F1F27',
    },
    hover: {
      borderColor: '#E5E7E9',
    },
    focus: {
      borderColor: '#3b83f6',
      labelColor: '#3b83f6',
    },
    disabled: {
      borderColor: '#c5cbd3',
      labelColor: '#c5cbd3',
    },
    error: {
      borderColor: '#EB5757',
      labelColor: '#EB5757',
      iconColor: '#EB5757',
    },
    textColor: '#1A1C1E',
    placeholderColor: '#B8B8B8',
    iconBackground: '#81869A',
  },
  select: {
    default: {
      color: '#B8B8B8',
      borderColor: '#B8B8B8',
    },
    disabled: {
      color: '#c5cbd3',
      borderColor: '#c5cbd3',
    },
    focus: {
      color: '#3b83f6',
      borderColor: '#3b83f6',
    },
    error: {
      borderColor: '#EB5757',
      color: '#EB5757',
    },
    tile: {
      hover: 'rgba(152, 170, 228, 0.12)',
      active: 'rgba(54, 97, 235, 0.12)',
    },
    placeholderColor: '#B8B8B8',
    textColor: '#1A1C1E',
  },
  checkbox: {
    active: {
      background: '#3b83f6',
    },
    inactive: {
      background: '#c5cbd3',
    },
    markColor: '#FFFFFF',
  },
  switch: {
    active: {
      background: '#3b83f6',
      circle: '#FFFFFF',
    },
    inactive: {
      background: '#c5cbd3',
      circle: '#FFFFFF',
    },
  },
  table: {
    buttonColor: '#3b83f6',
    buttonTextColor: '#FFFFFF',
    buttonDisabledColor: '#B8B8B8',
    tableTrOdd: '#f4f4f4',
    tableText: '#1A1C1E',
  },
  toast: {
    success: {
      background: 'rgba(39, 174, 96, 0.12)',
      color: '#27ae60',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    error: {
      background: 'rgba(235, 87, 87, 0.12)',
      color: '#eb5757',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    warning: {
      background: 'rgba(242, 153, 74, 0.12)',
      color: '#f2994a',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
    info: {
      background: 'rgba(54, 97, 235, 0.12)',
      color: '#3b83f6',
      boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.16)',
    },
  },
  authentication: {
    description: '#888888',
    text: '#FFFFFF',
    stepperBg: 'rgba(136, 136, 136, 0.2)',
    stepperBgActive: '#3b83f6',
    textInactiveStepper: 'rgba(255, 255, 255, 0.2)',
    cardBackground: 'rgba(255, 255, 255, 0.4)',
  },
};

const colors = {
  light: lightTheme,
  dark: darkTheme,
};

export const theming = (cb: (allCollor: typeof colors.light) => void) =>
  Object.keys(colors).reduce(
    (acc, name) =>
      Object.assign(acc, {
        [`.theme-${name} &`]: cb(colors[name]),
      }),
    {},
  );
