import { useUnit } from 'effector-react';

import { OTPCodeModal } from 'entities/Auth/ui/OTPCodeModal';
import { renderContent } from './lib/renderContent';
import { authPagesModel } from './model';
import { EAuthStages } from './types';
import { Box, Flex, Image } from '@chakra-ui/react';
import bgImage from 'shared/assets/images/authentication/icBg.png';
import { EnableOTP } from './ui/EnableOTP';

export const Auth = () => {
  const [currentStage] = useUnit([authPagesModel.stage.stores.$currentStage]);

  const onCancelSignIn = () => {
    authPagesModel.stage.events.setStage(EAuthStages.LOGIN);
  };

  if (currentStage === EAuthStages.ENABLE_OTP) return <EnableOTP />;

  return (
    <Flex width='100%' padding={8} columnGap={6}>
      <Box minHeight='1000px' height='calc(100vh - 64px)' aspectRatio='1 / 1.4'>
        <Image src={bgImage} height='100%' />
      </Box>
      <Box width='100%' minWidth='800px'>
        {renderContent[currentStage]}
      </Box>
      <OTPCodeModal
        onCancel={onCancelSignIn}
        modalData={authPagesModel.verifyOtp.modal}
        form={authPagesModel.verifyOtp.form}
      />
    </Flex>
  );
};
