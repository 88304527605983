import { TFilterRoutesByPermissions } from './type';

export const filterRoutesByPermissions: TFilterRoutesByPermissions = (
  selfPermissions,
  validPermissions,
) =>
  !selfPermissions ||
  !validPermissions?.length ||
  validPermissions.some((title) =>
    selfPermissions?.some((selfPermission) => selfPermission.title === title),
  );
