import { useUnit } from 'effector-react';
import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { stepperConfig } from 'pages/Auth/config';
import { authPagesModel } from 'pages/Auth/model';
import { StepperItem } from '../StepperItem';

export const Stepper = () => {
  const currentStep = useUnit(authPagesModel.otpSteps.stores.$currentStep);

  return (
    <Flex alignItems='center' justifyContent='space-between' width='100%'>
      {stepperConfig.map((el, index) => (
        <React.Fragment key={`${el.key}_${index}`}>
          <StepperItem
            number={index + 1}
            titleKey={el.title}
            isActive={currentStep >= index + 1}
          />
          {stepperConfig.length - 1 > index && (
            <Box
              marginTop={-7}
              width={100}
              height={0}
              border='1px solid'
              borderColor={currentStep >= index + 2 ? 'primary' : 'gray.300'}
            />
          )}
        </React.Fragment>
      ))}
    </Flex>
  );
};
