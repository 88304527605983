import { createEffect, createEvent, createStore, sample } from 'effector';
import { extractUserId } from 'entities/Auth/lib/extractUserId';
import { notificationsModel } from 'entities/Notification/model';
import { ENotificationType } from 'entities/Notification/types';
import { profileModel } from 'entities/Profile/model';
import { showToastEvent } from 'entities/Toast/model/showToast';
import { EToastStatus } from 'entities/Toast/types';
import { io, Socket } from 'socket.io-client';

const $socket = createStore<Socket>(null);

const initSocket = createEvent();
const initSocketFx = createEffect(async () => {
  const userId = extractUserId();

  const socket = io(import.meta.env.VITE_WS_SOCKET, {
    query: {
      userId,
    },
  });

  socket.on('connect', () => {
    console.log('Socket connected');
  });

  socket.on(ENotificationType.TEST_TASK, (event) => {
    console.log('Test task recieved', { event });
  });

  socket.on(ENotificationType.PROJECT_REMOVE_SUCCESS, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.PROJECT_REMOVE_FAIL, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.PROJECT_CREATE_SUCCESS, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
    showToastEvent({
      status: EToastStatus.Success,
      messageKey: 'projects.createSuccess',
    });
  });

  socket.on(ENotificationType.PROJECT_CREATE_FAIL, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
    showToastEvent({
      status: EToastStatus.Error,
      messageKey: 'projects.errors.create',
    });
  });

  socket.on(ENotificationType.PROJECT_UPDATE_SUCCESS, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
    showToastEvent({
      status: EToastStatus.Success,
      messageKey: 'projects.updateSuccess',
    });
  });

  socket.on(ENotificationType.PROJECT_UPDATE_FAIL, (event) => {
    notificationsModel.projects.events.addProjectNotification(
      JSON.parse(event),
    );
    showToastEvent({
      status: EToastStatus.Error,
      messageKey: 'projects.errors.update',
    });
  });

  socket.on(ENotificationType.QUOTA_APPROVE, (event) => {
    notificationsModel.quotas.events.addQuotaNotification(JSON.parse(event));
    profileModel.selfUser.events.getSelfUser();
  });

  socket.on(ENotificationType.QUOTA_REJECT, (event) => {
    notificationsModel.quotas.events.addQuotaNotification(JSON.parse(event));
    profileModel.selfUser.events.getSelfUser();
  });

  socket.on(ENotificationType.ASSIGN_FLOATING_IP_SUCCESS, (event) => {
    notificationsModel.floatingIp.events.addFloatingIpNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.ASSING_FLOATING_IP_FAIL, (event) => {
    notificationsModel.floatingIp.events.addFloatingIpNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.CLEAR_FLOATING_IP_SUCCESS, (event) => {
    notificationsModel.floatingIp.events.addFloatingIpNotification(
      JSON.parse(event),
    );
  });

  socket.on(ENotificationType.CLEAR_FLOATING_IP_FAIL, (event) => {
    notificationsModel.floatingIp.events.addFloatingIpNotification(
      JSON.parse(event),
    );
  });

  return socket;
});

sample({
  clock: initSocket,
  target: initSocketFx,
});

sample({
  clock: initSocketFx.doneData,
  target: $socket,
});

export const init = {
  stores: { $socket },
  events: { initSocket },
};
