import { Flex, Heading } from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { profileModel } from 'entities/Profile/model';
import React, { Fragment, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { TRouteGuard } from './types';

export const RouteGuard: React.FC<TRouteGuard> = ({
  component: Component,
  headerActions,
  permissions = [],
  path,
  title,
}) => {
  const [user, selfPermissions] = useUnit([
    profileModel.selfUser.stores.$selfUser,
    profileModel.selfUser.stores.$selfPermissions,
  ]);
  const { t } = useTranslation();
  const location = useLocation();

  // Permission check
  if (
    !user ||
    permissions.some(
      (perm) => !selfPermissions.some(({ title }) => title === perm),
    )
  ) {
    return null;
  }

  const isExactMatch = path
    ? matchPath({ path: path, end: true }, location.pathname)
    : null;

  const isChildMatch = path
    ? matchPath({ path: path, end: false }, location.pathname) && !isExactMatch
    : null;

  return (
    <Suspense fallback={<Fragment />}>
      {isExactMatch && (
        <>
          {(title || headerActions) && (
            <Flex
              alignItems='center'
              justifyContent='space-between'
              marginBottom={4}>
              <Heading size='md'>{t(title)}</Heading>
              {headerActions}
            </Flex>
          )}
          {Component && <Component />}
        </>
      )}
      <Outlet />
    </Suspense>
  );
};
