export enum ENotificationType {
  TEST_TASK = 'TEST_TASK',

  // Add project
  PROJECT_CREATE_SUCCESS = 'PROJECT_CREATE_SUCCESS',
  PROJECT_CREATE_FAIL = 'PROJECT_CREATE_FAIL',

  // Update project
  PROJECT_UPDATE_SUCCESS = 'PROJECT_UPDATE_SUCCESS',
  PROJECT_UPDATE_FAIL = 'PROJECT_UPDATE_FAIL',

  // Remove project
  PROJECT_REMOVE_SUCCESS = 'PROJECT_REMOVE_SUCCESS',
  PROJECT_REMOVE_FAIL = 'PROJECT_REMOVE_FAIL',

  // Quota
  QUOTA_APPROVE = 'QUOTA_APPROVE',
  QUOTA_REJECT = 'QUOTA_REJECT',

  // Flating IP
  ASSIGN_FLOATING_IP_SUCCESS = 'ASSIGN_FLOATING_IP_SUCCESS',
  ASSING_FLOATING_IP_FAIL = 'ASSING_FLOATING_IP_FAIL',

  // Clear floating IP
  CLEAR_FLOATING_IP_SUCCESS = 'CLEAR_FLOATING_IP_SUCCESS',
  CLEAR_FLOATING_IP_FAIL = 'CLEAR_FLOATING_IP_FAIL',
}

export enum ETaskType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export interface INotification {
  title: string;
  description: string;
  timestamp: number;
  type: ETaskType;
}
