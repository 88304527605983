import { useForm } from 'effector-forms';
import { useUnit } from 'effector-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'shared/ui/atoms/Button';
import { Input } from 'shared/ui/atoms/Input';
import { EAuthStages } from '../../types';

import { authPagesModel } from 'pages/Auth/model';
import { Text } from 'shared/ui/atoms/Text';
import { Flex, Heading, Button as ChakraButton } from '@chakra-ui/react';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { fields, submit, errorText } = useForm(
    authPagesModel.forgotPassword.form,
  );
  const [pending, isEmailLinkSend] = useUnit([
    authPagesModel.forgotPassword.stores.$pending,
    authPagesModel.forgotPassword.stores.$isEmailLinkSend,
  ]);

  const onLogin = () => {
    authPagesModel.forgotPassword.events.resetIsEmailLinkSend();
    authPagesModel.stage.events.setStage(EAuthStages.LOGIN);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Flex
      height='100%'
      position='relative'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <Heading textAlign='center' size='md' marginBottom={2}>
        {t(isEmailLinkSend ? 'forgotPassword.success' : 'forgotPassword.title')}
      </Heading>
      <Text
        color='secondary'
        props={{ textAlign: 'center', marginBottom: 8, width: 450 }}>
        {t(
          isEmailLinkSend
            ? 'forgotPassword.linkPrompt'
            : 'forgotPassword.description',
        )}
      </Text>

      {!isEmailLinkSend && (
        <form style={{ width: 400 }} onSubmit={onSubmit}>
          <Input
            props={{
              type: EInputFormatType.TEXT,
              inputProps: {
                name: fields.email.name,
                value: fields.email.value,
                placeholder: t('forgotPassword.emailPlaceholder'),
                onChange: (e) => fields.email.onChange(e.target.value),
                disabled: pending,
              },
            }}
            labelKey='authorization.login.email'
            errorKey={errorText('email')}
          />

          <Button
            pending={pending}
            props={{ width: '100%', marginTop: 5 }}
            type='submit'>
            {t('actions.reset')}
          </Button>
        </form>
      )}
      <ChakraButton
        marginTop={4}
        variant='plain'
        color='primary'
        onClick={onLogin}>
        <Text
          color='link'
          size='sm'
          props={{
            textDecoration: 'underline',
          }}>
          {t('forgotPassword.back')}
        </Text>
      </ChakraButton>
      <Text
        color='secondary'
        props={{ position: 'absolute', bottom: 0 }}
        size='sm'>
        {t('copyright')}
      </Text>
    </Flex>
  );
};
