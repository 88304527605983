import { useForm } from 'effector-forms';
import { useTranslation } from 'react-i18next';

import { Flex, Heading, VStack } from '@chakra-ui/react';
import { useUnit } from 'effector-react';
import { authPagesModel } from 'pages/Auth/model';
import { TOnChange, TOnSubmit } from 'shared/config/types';
import { Button } from 'shared/ui/atoms/Button';
import { Input } from 'shared/ui/atoms/Input';
import { EInputFormatType } from 'shared/ui/atoms/Input/types';

export const CreatePasswordForm = () => {
  const { t } = useTranslation();
  const pending = useUnit(authPagesModel.setPassword.stores.$pending);
  const { fields, submit, errorText } = useForm(
    authPagesModel.setPassword.form,
  );

  const onChange: TOnChange = (e) => {
    fields[e.target.name].onChange(e.target.value);
  };

  const onSubmit: TOnSubmit = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <Flex
      height='100%'
      position='relative'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      title='createPassword.title'>
      <Heading textAlign='center' size='md' marginBottom={8}>
        {t('createPassword.title')}
      </Heading>
      <form onSubmit={onSubmit} style={{ width: 400 }}>
        <VStack gap={6}>
          <Input
            props={{
              type: EInputFormatType.PASSWORD,
              inputProps: {
                name: fields.password.name,
                value: fields.password.value,
                onChange,
                placeholder: t('createPassword.placeholder'),
              },
            }}
            labelKey='createPassword.newPassword'
            errorKey={errorText('password')}
          />

          <Input
            props={{
              type: EInputFormatType.PASSWORD,
              inputProps: {
                name: fields.repeatPassword.name,
                value: fields.repeatPassword.value,
                onChange,
                placeholder: t('createPassword.placeholder'),
              },
            }}
            labelKey='createPassword.confirmation'
            errorKey={errorText('repeatPassword')}
          />

          <Button pending={pending} props={{ width: '100%' }} type='submit'>
            {t('createPassword.button')}
          </Button>
        </VStack>
      </form>
    </Flex>
  );
};
