import React from 'react';

import { Flex } from '@chakra-ui/react';
import { Notification } from '../Notification';
import { Profile } from '../Profile';
import IconLogo from 'shared/assets/svg/common/icLogo.svg?react';
import IconLogoLabel from 'shared/assets/svg/common/icLogoLabel.svg?react';

export const Navbar: React.FC = () => {
  return (
    <Flex
      alignItems='center'
      justifyContent='space-between'
      minHeight='60px'
      bg='surface.gradient'
      paddingInline={4}>
      <Flex alignItems='center' columnGap={2}>
        <IconLogo />
        <IconLogoLabel />
      </Flex>
      <Flex columnGap={4}>
        <Notification />
        <Profile />
      </Flex>
    </Flex>
  );
};
