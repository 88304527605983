import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { Box, Flex, Stack } from '@chakra-ui/react';
import { redirectModel } from 'processes/Router/model';
import { LeftSidebar } from './ui/LeftSidebar';
import { Navbar } from './ui/Navbar';

const Layout = () => {
  const redirectRoute = useUnit(redirectModel.$redirectRoute);
  const navigate = useNavigate();

  useEffect(() => {
    if (redirectRoute) {
      navigate(redirectRoute);
      redirectModel.setRedirectRoute(null);
    }
  }, [redirectRoute]);

  return (
    <Stack flexDirection='column' height='100vh' width={'100%'}>
      <Navbar />
      <Flex overflow='auto' padding={4} columnGap={4}>
        <LeftSidebar />
        <Flex flexDirection='column'>
          {/* <Breadcrumbs /> */}
          <Box width='calc(100vw - 298px)' position='relative' overflow='auto'>
            <Outlet />
          </Box>
        </Flex>
      </Flex>
    </Stack>
  );
};

export default Layout;
